






















































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { PackageDetails } from '../common/package-details'
import { PackageAssetType } from '../common/package-asset'
import DigitalSignatureShield from './digital-signature-shield.vue'
import {
  M42Button,
  M42Icon,
  M42Modal,
  M42Select,
  M42ToolTip,
  M42ContentScroller,
  M42FileList,
  M42Input,
  M42Spinner,
  // @ts-ignore
} from '@matrix42/ignition-vue'
import { PackageReview } from '../common/package-review'
import StarRating from './star-rating.vue'

@Component({
  components: {
    StarRating,
    DigitalSignatureShield,
    M42Modal,
    M42ToolTip,
    M42Select,
    M42Icon,
    M42Button,
    M42ContentScroller,
    M42FileList,
    M42Input,
    M42Spinner,
  },
})
export default class PackageDetailsModal extends Vue {
  public isExtensionShieldOpen: boolean = false
  public isVersionToolTipOpen: boolean = false
  public isDownloading: boolean = false
  public review: string = null
  public rating: number = 5
  public validated: boolean = false
  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean
  @Prop({
    type: Object,
    default: null,
  })
  public details: PackageDetails
  @Prop({
    type: Boolean,
    default: false,
  })
  public loading: boolean
  @Prop({
    type: Boolean,
    default: false,
  })
  public reviewsLoading: boolean
  @Prop({
    type: Object,
    default: null,
  })
  public reviews: {
    readonly totalCount: number
    readonly totalRating: number
    readonly items: PackageReview[]
  }

  @Watch('details', {
    deep: true,
    immediate: true,
  })
  public onDetailsChange(): void {
    this.preloadImages()
  }

  public get latestVersionSelected(): boolean {
    return this.details.versions.indexOf(this.details.selectedVersion) === 0
  }

  public get logoAsset(): string {
    if (!this.details) return null
    const logo = this.details.assets.filter((a) => a.assetType === PackageAssetType.Logo)[0]
    if (!logo) return null
    return logo.uri
  }

  public get imageAssets(): string[] {
    if (!this.details) return []
    return this.details.assets.filter((a) => a.assetType === PackageAssetType.Image).map((a) => a.uri)
  }

  public get documentAssets(): any[] {
    if (!this.details) return []
    return this.details.assets
      .filter((a) => a.assetType === PackageAssetType.Document)
      .map((a) => {
        return {
          id: a.id,
          label: a.name,
        }
      })
  }

  public get sortedCategories(): string[] {
    if (!this.details) return []
    const results: string[] = []
    if (this.details.categories.includes('Matrix42')) results.push('Matrix42')
    if (this.details.categories.includes('Certified Partner')) results.push('Certified Partner')
    if (this.details.categories.includes('Community')) results.push('Community')
    if (this.details.categories.includes('Private')) results.push('Private')
    results.push(
      ...this.details.categories
        .filter((c) => !results.includes(c))
        .sort((a, b) => {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        }),
    )
    return results
  }

  public openExtensionShieldHelp(): void {
    window.open('https://help.matrix42.com/030_DWP/070_DevOps_Portal/What_is_a_Digital_Signature%3F', '_blank')
  }

  public async preloadImages(): Promise<void> {
    if (!this.details) return
    this.$emit('update:loading', true)
    const promises: Promise<void>[] = []
    const images = this.details.assets.filter(
      (a) => a.assetType === PackageAssetType.Image || a.assetType === PackageAssetType.Logo,
    )
    for (const image of images) {
      const tmp = new Image()
      promises.push(
        new Promise<void>((resolve, reject) => {
          tmp.onload = () => resolve()
          tmp.onerror = (e) => reject(e)
        }),
      )
      tmp.src = image.uri
    }
    await Promise.all(promises)
    this.$emit('update:loading', false)
  }

  public downloadDocument(item: any): void {
    if (this.isDownloading) return
    this.isDownloading = true
    try {
      ;(<any>this).$popToast('success', `Downloading "${item.label}"...`)
      const asset = this.details.assets.filter((a) => a.id === item.id)[0]
      const hiddenAnchor = window.document.createElement('a')
      hiddenAnchor.style.display = 'none'
      hiddenAnchor.href = asset.uri
      hiddenAnchor.download = asset.name
      window.document.body.appendChild(hiddenAnchor)
      hiddenAnchor.click()
      window.document.body.removeChild(hiddenAnchor)
    } catch (e) {
      console.log(e)
      ;(<any>this).$popToast(
        'error',
        'There was an error downloading the document. Please try again later or contact our support.',
      )
    }
    this.isDownloading = false
  }

  public async onSendReview(): Promise<void> {
    this.validated = true
    if (!this.rating) return
    this.$emit('send-review', {
      content: this.review,
      rating: this.rating,
    })
    this.review = null
    this.rating = 0
    this.validated = false
  }
}
