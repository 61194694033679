





















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class DigitalSignatureShield extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  })
  public tag: boolean
}
