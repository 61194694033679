(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue-property-decorator"), require("vue"), require("@matrix42/ignition-vue"));
	else if(typeof define === 'function' && define.amd)
		define(["vue-property-decorator", , "@matrix42/ignition-vue"], factory);
	else if(typeof exports === 'object')
		exports["matrix42.extensions-common-components"] = factory(require("vue-property-decorator"), require("vue"), require("@matrix42/ignition-vue"));
	else
		root["matrix42.extensions-common-components"] = factory(root["vue-property-decorator"], root["Vue"], root["@matrix42/ignition-vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__01a6__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_b26c__) {
return 