import { Singleton } from '@dvolper/tsdi'
import { ContentType, HttpClient, HttpStatusCode } from '@matrix42/cloud-common'
import { InternalSoftwareRequirementAssertion, SoftwareRequirementAssertionResult } from '@/models/software-requirement'
import { InstallTarget } from '@/models/install-target'

@Singleton
export class HelperExtensionService {
  public constructor(private readonly _client: HttpClient) {}

  public async checkSoftwareRequirements(
    target: InstallTarget,
    assertions: InternalSoftwareRequirementAssertion[],
  ): Promise<SoftwareRequirementAssertionResult[]> {
    const response = await this._client.postAsync(
      target.host + 'm42services/api/m42bps/softwareRequirements/check',
      JSON.stringify(assertions),
      {
        Authorization: 'Bearer ' + target.accessToken,
        'Content-Type': ContentType.ApplicationJson,
      },
    )
    if (!response.isSuccessStatusCode) {
      throw new Error('Invalid status code returned when trying to check software requirements: ' + response.statusCode)
    }
    return response.content
  }
}
