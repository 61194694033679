


















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
// @ts-ignore
import { M42Icon } from '@matrix42/ignition-vue'

@Component({
  components: {
    M42Icon,
  },
})
export default class StarRating extends Vue {
  public hoverValue: number = 0

  @Prop({
    type: Boolean,
    default: false,
  })
  public readonly: boolean
  @Prop({
    type: Number,
    default: 5,
  })
  public rating: number

  public get displayRating(): number {
    if (this.readonly || !this.hoverValue) return this.rating
    return this.hoverValue
  }
}
