

























import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { M42Modal, ToastType } from '@matrix42/ignition-vue'
import {
  M42PackageModal,
  PackageAsset,
  PackageDetails,
  PackageInstallAction,
  PackageReview,
} from '@matrix42/extensions-common-components'
import { Resolve } from '@dvolper/tsdi'
import { PackageService } from '@/services/package-service'
import { Package } from '@matrix42/extension-installer'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { InstallTarget } from '@/models/install-target'
import { compareVersions, sortVersions } from '@matrix42/extension-installer'
import { InstalledPackage } from '@/models/installed-package'
import { PackageCategory } from '@/models/package-category'
import { ReviewService } from '@/services/review-service'
import { MarketplaceReviews } from '@/models/marketplace-reviews'
import ContactAuthorModal from '@/components/modals/contact-author-modal.vue'
import { validateVersions } from '@/common/version-utils'

@Component({
  components: {
    M42Modal,
    M42PackageModal,
    ContactAuthorModal,
  },
})
export default class Details extends Vue {
  @Resolve
  private readonly _packageService: PackageService
  @Resolve
  private readonly _reviewService: ReviewService
  public isLoading: boolean = true
  public packageInformation: Package = null
  public targetVersion: string = null
  public packageVersions: string[] = []
  public packageAssets: PackageAsset[] = []
  public packageReviews: MarketplaceReviews = null
  public packageReviewsCursor: number = 0
  public packageReviewPageSize: number = 5
  public reviewsLoading: boolean = false
  public contactAuthorOpen: boolean = false

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('installed_packages')
  public installedPackages: DataState<InstalledPackage>

  @Getter('identity_permissions')
  public packagePermissions: string[]

  @Getter('package_categories')
  public packageCategories: DataState<PackageCategory>

  @Watch('$route.params', {
    deep: true,
  })
  public async onRouteChange(): Promise<void> {
    if (this.isLoading) return
    await this.loadPackageInformation()
  }

  public get reviews(): {
    readonly totalCount: number
    readonly totalRating: number
    readonly items: PackageReview[]
  } {
    if (!this.packageReviews) return null
    return {
      items: this.packageReviews.data,
      totalCount: this.packageReviews.totalCount,
      totalRating: this.packageReviews.totalRating,
    }
  }

  public get packageDetails(): PackageDetails {
    if (!this.packageInformation) return null
    const installed = this.installedPackages.cache.filter((i) => i.Id === this.packageInformation.packageId)[0]
    let installAction: PackageInstallAction = PackageInstallAction.None
    if (
      this.packageInformation.commerceId &&
      !this.packageInformation.isLicensed &&
      !this.packagePermissions.includes(this.packageInformation.packageId)
    ) {
      installAction = PackageInstallAction.Purchase
    } else {
      const match = this.installedPackages.cache.filter((p) => p.Id === this.packageInformation.packageId)[0]
      if (match) {
        const latest = sortVersions(this.packageInformation.versions)[this.packageInformation.versions.length - 1]
        const compare = compareVersions(latest, match.Version, true)
        if (compare === 1) installAction = PackageInstallAction.Update
        else installAction = PackageInstallAction.Reinstall
      } else {
        installAction = PackageInstallAction.Install
      }
    }
    return {
      name: this.packageInformation.name,
      description: this.packageInformation.description,
      vendor: this.packageInformation.vendor,
      categories: this.packageInformation.categories.map((c) => {
        const match = this.packageCategories.cache.filter((pc) => pc.id === c)[0]
        if (!match) return c
        return match.displayName
      }),
      isSigned: this.packageInformation.isContentSigned,
      versions: this.packageVersions,
      selectedVersion: this.targetVersion,
      installedVersion: installed && installed.Version,
      installAction,
      assets: this.packageAssets,
      hasContact: true,
    }
  }

  public async mounted(): Promise<void> {
    await this.loadPackageInformation()
  }

  private async loadPackageInformation(): Promise<void> {
    this.isLoading = true
    try {
      const packageId = this.$router.currentRoute.params.packageId
      this.targetVersion = this.$router.currentRoute.params.version
      this.packageInformation = await this._packageService.getPackage(
        packageId,
        this.targetVersion,
        true,
        true,
        this.currentTarget && this.currentTarget.userEnvironmentSettings.allowPreviewPackages,
      )
      const result = validateVersions(this.targetVersion, this.packageInformation, this.installedPackages.cache)
      if (!result.versions.length) {
        await this.$router.push('/')
        return
      } else if (!result.selected) {
        this.isLoading = false
        this.onChangeTargetVersion(result.versions[0])
        return
      }
      this.packageVersions = result.versions
      this.packageAssets = await this._packageService.getPackageAssets(packageId, this.targetVersion)
      await this.loadReviews(true)
      this.isLoading = false
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error loading the required Extension. Please try again later or contact our support.',
      )
      await this.$router.push('/')
    }
  }

  private async loadReviews(reset?: boolean): Promise<void> {
    if (reset) {
      this.packageReviewsCursor = 0
    } else {
      this.packageReviewsCursor += this.packageReviewPageSize
    }
    this.reviewsLoading = true
    try {
      const packageId = this.$router.currentRoute.params.packageId
      const reviews = await this._reviewService.getPackageReviews(
        packageId,
        this.packageReviewsCursor,
        this.packageReviewPageSize,
      )
      if (reset || !this.packageReviews) {
        this.packageReviews = reviews
      } else {
        this.packageReviews.totalCount = reviews.totalCount
        this.packageReviews.totalRating = reviews.totalCount
        this.packageReviews.data.push(...reviews.data)
      }
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error loading the Extension Reviews. Please try again later or contact our support.',
      )
    }
    this.reviewsLoading = false
  }

  public onChangeTargetVersion(selectedVersion: string): void {
    const packageId = this.$router.currentRoute.params.packageId
    this.$router.push('/' + packageId + '/' + selectedVersion)
  }

  public onCloseModal(): void {
    this.$router.push('/')
  }

  public onInstall(): void {
    this.$router.push('/install/' + this.packageInformation.packageId + '/' + this.targetVersion)
  }

  public openContactAuthorModal(): void {
    const modal = <any>this.$refs.contactAuthorModal
    if (modal) modal.reset()
    this.contactAuthorOpen = true
  }

  public async onLoadReviews(): Promise<void> {
    await this.loadReviews()
  }

  public async onSendReview(review: { readonly content: string; readonly rating: number }): Promise<void> {
    this.reviewsLoading = true
    try {
      await this._reviewService.createPackageReview(this.packageInformation.packageId, review.rating, review.content)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error creating the Extension Review. Please try again later or contact our support.',
      )
    }
    await this.loadReviews(true)
  }
}
