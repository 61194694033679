



























































































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Resolve } from '@dvolper/tsdi'
import { PackageService } from '@/services/package-service'
import {
  M42Accordion,
  M42Banner,
  M42Button,
  M42Icon,
  M42Input,
  M42Modal,
  M42ProgressBar,
  M42Select,
  M42Spinner,
  M42Table,
  M42ToolTip,
  M42WizardProgressBar,
  TableColumn,
  ToastType,
  UiAction,
  UiActionState,
} from '@matrix42/ignition-vue'
import { InstallTarget } from '@/models/install-target'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import {
  compareVersions,
  InstallationPlan,
  InstallationPrerequisite,
  MismatchType,
  Package,
} from '@matrix42/extension-installer'
import { InstalledPackage } from '@/models/installed-package'
import { Configuration } from '@/models/configuration'
import { AcsUserProfile } from '@matrix42/cloud-common'
import {
  InternalSoftwareRequirementAssertion,
  SoftwareRequirement,
  SoftwareRequirementAssertionResult,
} from '@/models/software-requirement'
import { HelperExtensionService } from '@/services/helper-extension-service'
import { DwpConfigService } from '@/services/dwp-config-service'
import ContactAuthorModal from '@/components/modals/contact-author-modal.vue'
import { M42DigitalSignatureShield } from '@matrix42/extensions-common-components'
import ExtensionInstaller from '@/components/extension-installer.vue'
import { validateVersions } from '@/common/version-utils'

interface PrerequisiteTableRow extends InstallationPrerequisite {
  isInstalled?: any
}

@Component({
  components: {
    ExtensionInstaller,
    ContactAuthorModal,
    M42Modal,
    M42Banner,
    M42Button,
    M42Icon,
    M42Input,
    M42ProgressBar,
    M42Accordion,
    M42Select,
    M42Spinner,
    M42Table,
    M42ToolTip,
    M42WizardProgressBar,
    M42DigitalSignatureShield,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Installer extends Vue {
  @Resolve
  private readonly _packageService: PackageService
  @Resolve
  private readonly _helperExtensionService: HelperExtensionService
  @Resolve
  private readonly _dwpConfigService: DwpConfigService
  public installationSteps: UiAction[] = []
  public packageInformation: Package = null
  public contactAuthorOpen: boolean = false
  public targetVersion: string = null
  public isLoading: boolean = true
  public isVersionToolTipOpen: boolean = false
  public iFrameLoading: boolean = false
  public openManualPrerequisiteToolTip: number = -1
  public prerequisiteColumns: TableColumn[] = [
    {
      id: 'name',
      label: 'Name',
      size: 4,
    },
    {
      id: 'vendor',
      label: 'Vendor',
      size: 2,
    },
    {
      id: 'version',
      label: 'Version',
      size: 2,
    },
    {
      id: 'info',
      label: ' ',
      size: 3,
    },
    {
      id: 'actions',
      label: ' ',
      size: 1,
      align: 'right',
    },
  ]
  public packageVersions: string[] = []
  public messageListener: (e: MessageEvent) => void
  public marketplaceOrderConfirmed: boolean = false
  public isUninstallablePrerequisitesOpen: boolean = true
  public isInstallablePrerequisitesOpen: boolean = false
  public isSoftwareRequirementsLoading: boolean = false
  public lastSoftwareRequirementCheck: SoftwareRequirementAssertionResult[] = []
  public isHelperSupported: boolean = false
  public openExtensionShieldToolTip: number = -1
  public installationPlan: InstallationPlan = null
  public softwareRequirements: SoftwareRequirement[] = []
  public installationComplete: boolean = false
  public installationFailed: boolean = false

  @Getter('identity')
  public userProfile: AcsUserProfile

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('installed_packages')
  public installedPackages: DataState<InstalledPackage>

  @Getter('config')
  public configuration: Configuration

  @Getter('identity_permissions')
  public packagePermissions: string[]

  @Getter('latest_helper_version')
  public latestHelperVersion: string

  @Getter('is_latest_helper_installed')
  public isLatestHelperExtensionInstalled: boolean

  @Watch('isLatestHelperExtensionInstalled')
  public onLatestHelperInstalled(): void {
    if (
      this.isLatestHelperExtensionInstalled &&
      (this.activeStepId === 'step:confirm-installation' || this.activeStepId === 'step:purchase-extension')
    ) {
      this.oneStepForward()
    }
  }

  @Watch('$route.params', {
    deep: true,
  })
  public async onRouteChange(): Promise<void> {
    if (this.isLoading) return
    await this.loadPackageInformation()
  }

  public get installablePrerequisites(): PrerequisiteTableRow[] {
    if (!this.installationPlan) return []
    return this.installationPlan.installablePrerequisites.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  }

  public get uninstallablePrerequisites(): PrerequisiteTableRow[] {
    if (!this.installationPlan) return []
    return this.installationPlan.uninstallablePrerequisites
      .map((p) => {
        let isInstalled = p.isInstalled ? 1 : 0
        if (p.id !== 'Matrix42.DWP') {
          const result = this.lastSoftwareRequirementCheck.filter((c) => c.Key === p.id)[0]
          isInstalled = result == null ? (p.isInstalled ? 1 : 2) : result.Value ? 1 : 0
        }
        return {
          id: p.id,
          isInstalled,
          name: p.name,
          vendor: p.vendor,
          version: p.version,
          downloadUrl: p.downloadUrl,
          documentationUrl: p.documentationUrl,
          description: p.description,
          mismatchType: p.mismatchType,
        }
      })
      .sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
  }

  public get installablePrerequisitesInstallCount(): number {
    return this.installablePrerequisites.filter(
      (p) =>
        p.mismatchType !== MismatchType.UnreachableDueToNoAccess &&
        p.mismatchType !== MismatchType.IncompatibleVersionInstalled &&
        p.mismatchType !== MismatchType.UnreachableDueToNotPublishedOrObsolete &&
        p.mismatchType !== MismatchType.UnreachableDueToNotStable &&
        p.isInstalled == 0,
    ).length
  }

  public get hasNoAccessToDependency(): boolean {
    if (!this.installationPlan) return false
    return !!this.installationPlan.installablePrerequisites.filter(
      (p) => p.mismatchType == MismatchType.UnreachableDueToNoAccess,
    ).length
  }

  public get hasIncompatibleVersionInstalled(): boolean {
    if (!this.installationPlan) return false
    return !!this.installationPlan.installablePrerequisites.filter(
      (p) => p.mismatchType == MismatchType.IncompatibleVersionInstalled,
    ).length
  }

  public get hasObsoleteDependency(): boolean {
    if (!this.installationPlan) return false
    return !!this.installationPlan.installablePrerequisites.filter(
      (p) => p.mismatchType == MismatchType.UnreachableDueToNotPublishedOrObsolete,
    ).length
  }

  public get hasUnreachableDependency(): boolean {
    if (!this.installationPlan) return false
    return !!this.installationPlan.installablePrerequisites.filter(
      (p) => p.mismatchType == MismatchType.UnreachableDueToNotStable,
    ).length
  }

  public get hasProductVersionMismatch(): boolean {
    if (!this.installationPlan) return false
    return !!this.installationPlan.uninstallablePrerequisites.filter(
      (p) => p.mismatchType == MismatchType.ProductVersionMismatch,
    ).length
  }

  public get hasDependencyIssues(): boolean {
    return (
      this.hasNoAccessToDependency ||
      this.hasUnreachableDependency ||
      this.hasIncompatibleVersionInstalled ||
      this.hasObsoleteDependency
    )
  }

  public get hasPrerequisites(): boolean {
    return (
      this.installationPlan &&
      (!!this.installationPlan.installablePrerequisites.length ||
        !!this.installationPlan.uninstallablePrerequisites.length)
    )
  }

  public get allUninstallablePrerequisitesInstalled(): boolean {
    if (this.isHelperSupported) {
      return (
        this.uninstallablePrerequisites.filter((p) => p.isInstalled === 1).length ===
        this.uninstallablePrerequisites.length
      )
    }
    return (
      this.uninstallablePrerequisites.filter((p) => p.isInstalled !== 0).length ===
      this.uninstallablePrerequisites.length
    )
  }

  public get hasUnknownManualPrerequisite(): boolean {
    return this.uninstallablePrerequisites.filter((p) => p.isInstalled === 2).length > 0
  }

  public get hasCustomerNumber(): boolean {
    return this.userProfile.EnterpriseAccount && !!this.userProfile.EnterpriseAccount.CustomerNumber
  }

  public get needsPurchase(): boolean {
    if (!this.packageInformation) return false
    return (
      this.packageInformation.commerceId &&
      !this.packageInformation.isLicensed &&
      !this.packagePermissions.includes(this.packageInformation.packageId)
    )
  }

  public get purchaseUrl(): string {
    return (
      this.configuration.m42MarketplaceBaseUrl +
      'exGa/?mx42exgaid=' +
      encodeURIComponent(this.packageInformation.commerceId) +
      '&mx42exgaqty=1'
    )
  }

  public get latestVersionSelected(): boolean {
    return this.packageVersions.indexOf(this.targetVersion) === 0
  }

  public get activeStepIndex(): number {
    for (let index = 0; index < this.installationSteps.length; index++) {
      if (this.installationSteps[index].state === UiActionState.Active) return index
    }
    return undefined
  }

  public get activeStepId(): string {
    for (let index = 0; index < this.installationSteps.length; index++) {
      if (this.installationSteps[index].state === UiActionState.Active) return this.installationSteps[index].id
    }
    return undefined
  }

  public get packageIsNotSupported(): boolean {
    if (!this.packageInformation) return false
    return (
      this.packageInformation.categories.includes('Community') ||
      this.packageInformation.categories.includes('Unstable') ||
      this.packageInformation.categories.includes('Release Candidate') ||
      this.packageInformation.categories.includes('Technical Preview')
    )
  }

  public get maintenanceMode(): boolean {
    return (
      (this.packageInformation && this.packageInformation.maintenanceMode) ||
      (this.installationPlan && this.installationPlan.requiresMaintenanceMode)
    )
  }

  public get uninstallablePrerequisitesActions(): UiAction[] {
    return !this.isHelperSupported ? [] : [{ id: 'refresh', icon: 'refresh', label: 'Refresh' }]
  }

  public get allowPreviewPackages(): boolean {
    return (
      this.currentTarget &&
      this.currentTarget.userEnvironmentSettings &&
      this.currentTarget.userEnvironmentSettings.allowPreviewPackages
    )
  }

  public onCloseModal(): void {
    this.$router.push('/')
  }

  public onFinishInstallation(): void {
    let launchUri = this.currentTarget.host
    if (this.packageInformation.launchUri.startsWith('/')) {
      launchUri += this.packageInformation.launchUri.substr(1)
    } else {
      launchUri += this.packageInformation.launchUri
    }
    window.open(launchUri, '_blank')
    this.onCloseModal()
  }

  public async mounted(): Promise<void> {
    this.messageListener = (e) => {
      if (e.data === 'mx42-mpl-exga-confirm') {
        this.$store.commit('require_packages_refresh', true)
        this.marketplaceOrderConfirmed = true
      }
    }
    window.addEventListener('message', this.messageListener)
    await this.loadPackageInformation()
    if (this.currentTarget) {
      try {
        const config = await this._dwpConfigService.getConfiguration(this.currentTarget)
        this.isHelperSupported = compareVersions(config.productVersion, '10.1.1') >= 0
      } catch (e) {
        console.log(e)
      }
    }
  }

  public openExtensionShieldHelp(): void {
    window.open('https://help.matrix42.com/030_DWP/070_DevOps_Portal/What_is_a_Digital_Signature%3F', '_blank')
  }

  public destroyed(): void {
    window.removeEventListener('message', this.messageListener)
  }

  private deactivateAllSteps(): void {
    for (const step of this.installationSteps) {
      Vue.set(step, 'state', 0)
    }
  }

  public oneStepBack(): void {
    const index = this.activeStepIndex
    this.deactivateAllSteps()
    this.installationSteps[index - 1].state = UiActionState.Active
  }

  public async oneStepForward(): Promise<void> {
    const index = this.activeStepIndex
    const nextStep = this.installationSteps[index + 1]
    switch (nextStep.id) {
      case 'step:prerequisites':
      case 'step:install-extension':
        if (this.packageInformation.isObsolete || (this.needsPurchase && !this.hasCustomerNumber)) return
        if (!this.currentTarget) {
          this.$store.commit('require_environment', true)
          return
        }
        if (!this.currentTarget.accessToken) {
          this.$store.commit('require_session_refresh', this.currentTarget.host)
          return
        }
        if (nextStep.id === 'step:prerequisites' && this.softwareRequirements.length) {
          if (!this.isLatestHelperExtensionInstalled && this.isHelperSupported) {
            this.$store.commit('set_install_gallery_helper', true)
            return
          }
        }
        break
    }
    this.deactivateAllSteps()
    nextStep.state = UiActionState.Active
    switch (nextStep.id) {
      case 'step:prerequisites':
        if (this.isHelperSupported) {
          await this.checkUninstallablePrerequisites()
        }
        break
      case 'step:install-extension':
        await this.startInstallation()
        break
      case 'step:purchase-extension':
        this.iFrameLoading = true
        this.$nextTick(() => {
          const iFrame = <HTMLIFrameElement>this.$refs.iFrame
          iFrame.onload = () => {
            this.iFrameLoading = false
          }
        })
        break
    }
  }

  private async checkUninstallablePrerequisites(): Promise<void> {
    this.isSoftwareRequirementsLoading = true
    try {
      const assertions: InternalSoftwareRequirementAssertion[] = []
      for (const requirement of this.softwareRequirements) {
        assertions.push(
          ...requirement.assertions.map((a) => {
            return {
              ...a,
              softwareIdentifier: requirement.productName + '-' + requirement.requiredVersion,
            }
          }),
        )
      }
      if (assertions.length) {
        this.lastSoftwareRequirementCheck = await this._helperExtensionService.checkSoftwareRequirements(
          this.currentTarget,
          assertions,
        )
      }
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error checking the software requirements. Please try again later or contact our support.',
      )
    }
    this.isSoftwareRequirementsLoading = false
  }

  private async loadPackageInformation(): Promise<void> {
    this.isLoading = true
    this.installationSteps = [
      {
        id: 'step:confirm-installation',
        label: 'Confirm Installation',
        state: UiActionState.Active,
      },
    ]
    try {
      this.packageVersions = []
      this.installationPlan = null
      this.softwareRequirements = []
      const packageId = this.$router.currentRoute.params.packageId
      this.targetVersion = this.$router.currentRoute.params.version
      this.packageInformation = await this._packageService.getPackage(
        packageId,
        this.targetVersion,
        null,
        null,
        this.allowPreviewPackages,
      )
      const result = validateVersions(this.targetVersion, this.packageInformation, this.installedPackages.cache)
      if (!result.versions.length) {
        await this.$router.push('/')
        return
      } else if (!result.selected) {
        this.isLoading = false
        this.onChangeTargetVersion(result.versions[0])
        return
      }
      this.packageVersions = result.versions
      if (this.needsPurchase) {
        if (!this.hasCustomerNumber) {
          const companyName = this.userProfile.EnterpriseAccount
            ? this.userProfile.EnterpriseAccount.AccountName
            : this.userProfile.CompanyName
          this.$popToast(
            ToastType.Error,
            `You cannot proceed in purchasing the Extension since there is no customer number linked to your company account (${companyName}). Please contact our <a href="mailto:salesadministration@matrix42.com">Sales Administration</a> to register as a Matrix42 customer.`,
          )
        }
        this.installationSteps.push({
          id: 'step:purchase-extension',
          label: 'Purchase Extension',
        })
      }
      this.installationPlan = await this._packageService.getPackageInstallationPlan(
        packageId,
        this.targetVersion,
        this.currentTarget,
        this.allowPreviewPackages,
      )
      this.softwareRequirements = await this._packageService.getPackageSoftwareRequirements(
        packageId,
        this.targetVersion,
      )
      for (const prerequisite of this.installationPlan.installablePrerequisites) {
        const requirements = await this._packageService.getPackageSoftwareRequirements(
          prerequisite.id,
          prerequisite.version,
        )
        this.softwareRequirements.push(...requirements)
      }
      if (this.hasPrerequisites) {
        if (!this.uninstallablePrerequisites.length) this.isInstallablePrerequisitesOpen = true
        this.installationSteps.push({
          id: 'step:prerequisites',
          label: 'Prerequisites',
        })
      }
      this.installationSteps.push({
        id: 'step:install-extension',
        label: 'Install Extension',
      })
      this.isLoading = false
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error loading the required Extension. Please try again later or contact our support.',
      )
      await this.$router.push('/')
    }
  }

  public onChangeTargetVersion(selectedVersion: string): void {
    const packageId = this.$router.currentRoute.params.packageId
    this.$router.push('/install/' + packageId + '/' + selectedVersion)
  }

  public async onRetryInstallation(): Promise<void> {
    this.installationFailed = false
    this.installationComplete = false
    await this.startInstallation()
  }

  public openContactAuthorModal(): void {
    const modal = <any>this.$refs.contactAuthorModal
    if (modal) modal.reset()
    this.contactAuthorOpen = true
  }

  public startInstallation(): void {
    if (this.hasDependencyIssues || !this.allUninstallablePrerequisitesInstalled) return
    this.$nextTick(async () => {
      const extensionInstaller = <any>this.$refs.extensionInstaller
      const success = await extensionInstaller.startInstallation()
      if (success) this.installationComplete = true
      else this.installationFailed = true
    })
  }

  public openEnvironmentSettings(): void {
    this.$store.commit('require_environment_settings', true)
  }
}
