


























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Button, M42Input, M42Modal, ToastType } from '@matrix42/ignition-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Package } from '@matrix42/extension-installer'
import { Resolve } from '@dvolper/tsdi'
import { PackageService } from '@/services/package-service'

@Component({
  components: {
    M42Modal,
    M42Input,
    M42Button,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ContactAuthorModal extends Vue {
  @Resolve
  private readonly _packageService: PackageService
  public subject: string = null
  public message: string = null
  public isLoading: boolean = false

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Prop({
    type: Object,
    default: null,
  })
  public target: Package

  @Prop({
    type: String,
    default: null,
  })
  public targetVersion: string

  public async onContactAuthor(): Promise<void> {
    const form = <any>this.$refs.form
    if (!(await form.validate())) return
    this.isLoading = true
    try {
      await this._packageService.contactPackageAuthor(
        this.target.packageId,
        this.targetVersion,
        this.subject,
        this.message,
      )
      this.$emit('update:open', false)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error sending your Message. Please try again later or contact our support.',
      )
    }
  }

  public reset(): void {
    this.subject = null
    this.message = null
    this.isLoading = false
    const form = <any>this.$refs.form
    if (form) form.reset()
  }
}
