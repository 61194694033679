import { InstalledPackage } from '@/models/installed-package'
import { Package } from '@matrix42/extension-installer'
import { compareVersions, sortVersions } from '@matrix42/extension-installer'

export const validateVersions = (
  targetVersion: string,
  packageInformation: Package,
  installedPackages: InstalledPackage[],
): {
  readonly versions: string[]
  readonly selected: string
} => {
  const packageVersions: string[] = []
  const sorted = sortVersions(packageInformation.versions).reverse()
  const match = installedPackages.filter((i) => i.Id === packageInformation.packageId)[0]
  for (const version of sorted) {
    if (match && compareVersions(version, match.Version, true) === -1) continue
    packageVersions.push(version)
  }
  if (!packageVersions.length) {
    return {
      versions: [],
      selected: null,
    }
  }
  if (!targetVersion || !packageVersions.includes(targetVersion)) {
    return {
      versions: packageVersions,
      selected: null,
    }
  }
  return {
    versions: packageVersions,
    selected: targetVersion,
  }
}
