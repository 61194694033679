










































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { Resolve } from '@dvolper/tsdi'
import { PackageService } from '@/services/package-service'
import { Package } from '@matrix42/extension-installer'
import {
  M42Banner,
  M42Button,
  M42Gallery,
  M42Icon,
  M42SearchBar,
  M42Spinner,
  M42ToolTip,
  UiAction,
  UiActionState,
} from '@matrix42/ignition-vue'
import { debounce } from '@/utils/debounce'
import GalleryIllustration from '@/components/gallery-illustration.vue'
import { compareVersions, sortVersions } from '@matrix42/extension-installer'
import { InstalledPackage } from '@/models/installed-package'
import { PackageCategory } from '@/models/package-category'
import { InstallTarget } from '@/models/install-target'
import { M42DigitalSignatureShield, PackageInstallAction } from '@matrix42/extensions-common-components'
import { getHashParameter, removeHashParameter, setHashParameter } from '@/common/hash-utils'

@Component({
  components: {
    GalleryIllustration,
    M42Banner,
    M42Button,
    M42Gallery,
    M42SearchBar,
    M42Spinner,
    M42Icon,
    M42ToolTip,
    M42DigitalSignatureShield,
  },
})
export default class Gallery extends Vue {
  @Resolve
  private readonly _packageService: PackageService
  public loadingCursor: number = 0
  public allPackagesLoaded: boolean = true
  public searchQuery: string = ''
  public searchCategories: UiAction[] = []
  public debouncedQuery: () => Promise<void> = null
  public openExtensionShieldToolTip: number = -1
  public lastSearchQuery: string = null
  public queryMenuOpen: boolean = false
  public activeCategory: string = 'All'
  public selectedQueryOption: string = null
  public tmpCategory: UiAction = null

  @Getter('packages')
  public packages: DataState<Package>

  @Getter('installed_packages')
  public installedPackages: DataState<InstalledPackage>

  @Getter('installed_packages_mapped')
  public installedPackagesMapped: DataState<Package>

  @Getter('available_updates_count')
  public updateCount: number

  @Getter('refresh_packages')
  public refreshPackages: boolean

  @Getter('identity_permissions')
  public packagePermissions: string[]

  @Getter('content_scroll')
  public contentScroll: number

  @Getter('package_categories')
  public packageCategories: DataState<PackageCategory>

  @Getter('current_target')
  public currentTarget: InstallTarget

  @Getter('invalidate_search_categories')
  public invalidateSearchCategories: boolean

  @Watch('searchQuery')
  public onSearchQuery(): void {
    this.debouncedQuery()
  }

  @Watch('refreshPackages')
  public async onRefreshPackagesChange(): Promise<void> {
    if (this.refreshPackages) {
      await this.loadPackages()
      this.$store.commit('require_packages_refresh', false)
    }
  }

  @Watch('invalidateSearchCategories')
  public onInvalidateSearchCategories(): void {
    if (this.invalidateSearchCategories) {
      this.setSearchCategories()
      if (this.searchCategories.filter((c) => c.id === this.activeCategory).length) {
        this.selectSearchCategory(this.activeCategory, true)
      } else {
        this.selectSearchCategory('All')
      }
      this.$store.commit('set_invalidate_search_categories', false)
    }
  }

  public get groupedPackageCategories(): {
    readonly topic: string
    readonly categories: PackageCategory[]
  }[] {
    const result: {
      topic: string
      categories: PackageCategory[]
    }[] = []
    for (const category of this.packageCategories.cache.sort((a, b) => {
      if (a.topic < b.topic) return -1
      if (a.topic > b.topic) return 1
      return 0
    })) {
      const existing = result.filter((r) => r.topic === category.topic)[0]
      if (existing) {
        existing.categories.push(category)
        existing.categories = existing.categories.sort((a, b) => {
          if (a.displayName < b.displayName) return -1
          if (a.displayName > b.displayName) return 1
          return 0
        })
      } else {
        result.push({
          topic: category.topic,
          categories: [category],
        })
      }
    }
    return result
  }

  public get isPromoAreaBlurred(): boolean {
    if (!this.contentScroll) return false
    const promoArea = <HTMLDivElement>this.$refs.promoArea
    if (!promoArea) return false
    return this.contentScroll >= promoArea.offsetHeight - 64
  }

  public getInstallAction(pkg: Package): PackageInstallAction {
    if (pkg.commerceId && !pkg.isLicensed && !this.packagePermissions.includes(pkg.packageId))
      return PackageInstallAction.Purchase
    const match = this.installedPackages.cache.filter((p) => p.Id === pkg.packageId)[0]
    if (match) {
      const latest = this.getLatestVersion(pkg)
      const compare = compareVersions(latest, match.Version, true)
      if (compare === -1) return PackageInstallAction.None
      if (compare === 1) return PackageInstallAction.Update
      return PackageInstallAction.Reinstall
    }
    return PackageInstallAction.Install
  }

  public getInstalledVersion(pkg: Package): string {
    const match = this.installedPackages.cache.filter((p) => p.Id === pkg.packageId)[0]
    if (match) return match.Version
    return null
  }

  public installPackage(pkg: Package): void {
    if (this.packages.isLoading || this.getInstallAction(pkg) === PackageInstallAction.None) return
    const path = '/install/' + pkg.packageId + '/' + this.getLatestVersion(pkg)
    if (this.$route.path === path) return
    this.$router.push(path)
  }

  public openPackage(pkg: Package): void {
    if (this.packages.isLoading) return
    const path = '/' + pkg.packageId
    if (this.$route.path === path) return
    this.$router.push(path)
  }

  public async mounted(): Promise<void> {
    this.$store.commit('set_subtitles', [])
    this.debouncedQuery = debounce(async () => {
      await this.loadPackages()
    }, 250)
    this.setSearchCategories()
    const category = getHashParameter('category')
    if (this.searchCategories.filter((c) => c.id === category).length) {
      this.selectSearchCategory(category, true)
    }
    await this.loadPackages()
  }

  private setSearchCategories(): void {
    const categories: UiAction[] = [
      {
        id: 'All',
        label: 'All',
        state: 1,
      },
      {
        id: 'Matrix42',
        label: 'Matrix42',
      },
      {
        id: 'Certified Partner',
        label: 'Certified Partner',
      },
      {
        id: 'Community',
        label: 'Community',
      },
      {
        id: 'Private',
        label: 'Private',
      },
    ]
    if (this.installedPackages.cache.length) {
      categories.push({
        id: 'Installed',
        label: 'Installed',
      })
    }
    if (!this.tmpCategory) this.searchCategories = categories
    else {
      this.searchCategories = [this.tmpCategory, ...categories]
    }
  }

  private async loadPackages(append: boolean = false): Promise<void> {
    this.$store.commit('start_loading', 'packages')
    if (!append) this.loadingCursor = 0
    try {
      if (this.activeCategory === 'Installed') {
        this.allPackagesLoaded = true
        this.$store.commit('finish_loading', {
          key: 'packages',
          data: this.installedPackagesMapped.cache.filter((p) => {
            if (!this.searchQuery) return true
            const query = this.searchQuery.toLowerCase()
            return (
              p.name.toLowerCase().includes(query) ||
              p.description.toLowerCase().includes(query) ||
              !!p.versions.filter((v) => v.toLowerCase().includes(query)).length ||
              p.vendor.toLowerCase().includes(query)
            )
          }),
        })
        return
      }
      const result = await this._packageService.searchPackages(
        this.searchQuery,
        this.activeCategory,
        true,
        this.currentTarget && this.currentTarget.userEnvironmentSettings.allowPreviewPackages,
        this.loadingCursor,
        12,
      )
      const packages = []
      if (append) packages.push(...this.packages.cache)
      packages.push(...result.items)
      this.loadingCursor += result.items.length
      this.allPackagesLoaded = result.items.length < 12
      this.lastSearchQuery = this.searchQuery
      this.$store.commit('finish_loading', {
        key: 'packages',
        data: packages,
      })
    } catch (e) {
      console.log(e)
      this.$store.commit('finish_loading', {
        key: 'packages',
        data: e,
      })
    }
  }

  public getLatestVersion(item: Package): string {
    return sortVersions(item.versions)[item.versions.length - 1]
  }

  public openExtensionShieldHelp(): void {
    window.open('https://help.matrix42.com/030_DWP/070_DevOps_Portal/What_is_a_Digital_Signature%3F', '_blank')
  }

  public selectSearchCategory(id: string, noLoad: boolean = false): void {
    for (const category of this.searchCategories) {
      Vue.set(category, 'state', UiActionState.Default)
      if (category.id === id) {
        category.state = UiActionState.Active
        this.onActiveCategory(category, noLoad)
      }
    }
  }

  public onQueryCategoryClick(category: PackageCategory): void {
    this.queryMenuOpen = false
    this.selectedQueryOption = category.id
    this.tmpCategory = {
      id: category.id,
      label: category.displayName,
    }
    this.setSearchCategories()
    this.selectSearchCategory(category.id)
  }

  public onActiveCategory(category: UiAction, noLoad: boolean = false): void {
    this.activeCategory = category.id
    const first = this.searchCategories[0]
    if (first.id !== 'All' && first.id !== category.id) {
      this.selectedQueryOption = null
      this.tmpCategory = null
      this.setSearchCategories()
    }
    if (category.id !== 'All') {
      setHashParameter('category', category.id)
    } else {
      removeHashParameter('category')
    }
    if (!noLoad) this.debouncedQuery()
  }
}
