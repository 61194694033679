






























































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {
  M42Banner,
  M42Button,
  M42Icon,
  M42Spinner,
  M42Table,
  TableColumn,
  UiAction,
  UiActionType,
} from '@matrix42/ignition-vue'
import { Getter } from 'vuex-class'
import { DataState } from '@/store/app-state'
import { CustomerProfile, ServiceConnectionState } from '@/models/customer-profile'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfileService } from '@/services/customer-profile-service'
import CreateCustomerProfileModal from '@/components/modals/create-customer-profile-modal.vue'
import DeleteCustomerProfileModal from '@/components/modals/delete-customer-profile-modal.vue'
import EditCustomerProfileAside from '@/components/asides/edit-customer-profile-aside.vue'
import RecreateCustomerProfileModal from '@/components/modals/recreate-customer-profile-modal.vue'

@Component({
  components: {
    RecreateCustomerProfileModal,
    EditCustomerProfileAside,
    DeleteCustomerProfileModal,
    CreateCustomerProfileModal,
    M42Banner,
    M42Button,
    M42Icon,
    M42Spinner,
    M42Table,
  },
})
export default class CustomerProfiles extends Vue {
  private static readonly _showHintStorageKey = 'M42-SHOW-CP-HINT'
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  public isLoading: boolean = true
  public showHint: boolean = false
  public isCreateCustomerProfileModalOpen: boolean = false
  public isDeleteCustomerProfileModalOpen: boolean = false
  public isEditCustomerProfileAsideOpen: boolean = false
  public isRecreateCustomerProfileModalOpen: boolean = false
  public targetProfile: CustomerProfile = null
  public tableColumns: TableColumn[] = [
    {
      id: 'name',
      label: 'Name',
      size: 4,
    },
    {
      id: 'action',
      label: ' ',
      size: 2,
    },
    {
      id: 'vendorName',
      label: 'Tenant',
      size: 2,
    },
    {
      id: 'installationEnvironments',
      label: 'Environments',
      size: 2,
      align: 'right',
    },
    {
      id: 'tags',
      label: ' ',
      size: 2,
      align: 'right',
    },
  ]

  @Getter('customer_profiles')
  public customerProfiles: DataState<CustomerProfile>

  @Getter('selected_customer_profile')
  public selectedCustomerProfile: string

  public get tableActions(): UiAction[][][] {
    const actions: UiAction[][][] = []
    for (const profile of this.customerProfiles.cache) {
      const rowActions: UiAction[][] = []
      const basicActions: UiAction[] = []
      if (profile.serviceConnectionState == null || profile.serviceConnectionState === ServiceConnectionState.Healthy) {
        basicActions.push({
          id: 'edit',
          label: 'Edit',
          icon: 'edit',
        })
      }
      if (
        profile.serviceConnectionState == ServiceConnectionState.Denied ||
        profile.serviceConnectionState == ServiceConnectionState.Unhealthy
      ) {
        basicActions.push({
          id: 'request',
          label: 'Request Access',
          icon: 'email',
        })
      }
      rowActions.push(basicActions)
      if (!profile.isDefault) {
        rowActions.push([
          {
            id: 'delete',
            label: 'Delete',
            icon: 'delete',
            type: UiActionType.Danger,
          },
        ])
      }
      actions.push(rowActions)
    }
    return actions
  }

  public async mounted(): Promise<void> {
    this.$store.commit('set_subtitles', [
      {
        id: '/',
        label: 'Extension Gallery',
      },
      {
        id: 'customer-profiles',
        label: 'Manage Customer Profiles',
      },
    ])
    try {
      const showHint = JSON.parse(window.localStorage.getItem(CustomerProfiles._showHintStorageKey))
      if (showHint != null) this.showHint = showHint
      else this.showHint = true
    } catch (e) {
      console.log(e)
      this.showHint = true
    }
    this.isLoading = false
  }

  private async loadProfiles(): Promise<void> {
    this.$store.commit('start_loading', 'customer_profiles')
    try {
      const profiles = await this._customerProfileService.getCustomerProfiles()
      this.$store.commit('finish_loading', {
        key: 'customer_profiles',
        data: profiles,
      })
    } catch (e) {
      console.log(e)
      this.$store.commit('finish_loading', {
        key: 'customer_profiles',
        data: e,
      })
    }
  }

  public hideHint(): void {
    window.localStorage.setItem(CustomerProfiles._showHintStorageKey, JSON.stringify(false))
    this.showHint = false
  }

  public onTableAction(action: UiAction, profile: CustomerProfile): void {
    this.targetProfile = profile
    this.$nextTick(() => {
      switch (action.id) {
        case 'delete':
          this.onDeleteCustomerProfile(profile)
          return
        case 'edit':
          this.onEditCustomerProfile(profile)
          return
        case 'request':
          this.onRecreateCustomerProfile(profile)
          return
      }
    })
  }

  public onNewCustomerProfile(): void {
    const modal = <any>this.$refs.createCustomerProfileModal
    if (modal) modal.reset()
    this.isCreateCustomerProfileModalOpen = true
  }

  private onEditCustomerProfile(profile: CustomerProfile): void {
    const aside = <any>this.$refs.editCustomerProfileAside
    if (aside) aside.reset()
    this.isEditCustomerProfileAsideOpen = true
  }

  private onDeleteCustomerProfile(profile: CustomerProfile): void {
    const modal = <any>this.$refs.deleteCustomerProfileModal
    if (modal) modal.reset()
    this.isDeleteCustomerProfileModalOpen = true
  }

  private onRecreateCustomerProfile(profile: CustomerProfile): void {
    const aside = <any>this.$refs.recreateCustomerProfileAside
    if (aside) aside.reset()
    this.isRecreateCustomerProfileModalOpen = true
  }

  public async onCustomerProfileSaved(profile: CustomerProfile): Promise<void> {
    this.isEditCustomerProfileAsideOpen = false
    if (profile.id === this.selectedCustomerProfile) {
      window.location.reload()
    } else {
      await this.loadProfiles()
    }
  }

  public async onCustomerProfileCreated(profile: CustomerProfile): Promise<void> {
    this.isCreateCustomerProfileModalOpen = false
    await this.loadProfiles()
  }

  public async onCustomerProfileRecreated(profile: CustomerProfile): Promise<void> {
    this.isRecreateCustomerProfileModalOpen = false
    if (profile.id === this.selectedCustomerProfile) {
      window.location.reload()
    } else {
      await this.loadProfiles()
    }
  }

  public async onCustomerProfileDeleted(profile: CustomerProfile): Promise<void> {
    this.isDeleteCustomerProfileModalOpen = false
    if (profile.id === this.selectedCustomerProfile) {
      window.location.reload()
    } else {
      await this.loadProfiles()
    }
  }

  public onSwitchProfile(profile: CustomerProfile): void {
    this.isLoading = true
    window.localStorage.setItem('M42-LAST-SELECTED-PROFILE', profile.id)
    window.location.reload()
  }

  public onGoBack(): void {
    this.$router.push('/')
  }
}
