




















































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { M42Banner, M42Button, M42Input, M42Modal, M42Select, M42Spinner, ToastType } from '@matrix42/ignition-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Getter } from 'vuex-class'
import { AcsUserProfile } from '@matrix42/cloud-common'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { debounce } from '@/utils/debounce'
import { Customer } from '@/models/customer'
import CustomerService from '@/services/customer-service'
import { ApiError } from '@/common/api-error'

@Component({
  components: {
    M42Banner,
    M42Button,
    M42Input,
    M42Modal,
    M42Spinner,
    M42Select,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CreateCustomerProfileModal extends Vue {
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  @Resolve
  private readonly _customerService: CustomerService
  public isLoading: boolean = false
  public profileName: string = null
  public useCustomerNumber: boolean = false
  public customerNumber: string = null
  public debouncedOnCustomerNumberChange: () => void = null
  public isCustomerNumberValidating: boolean = false
  public customers: Customer[] = []
  public selectedTenant: string = null
  public requireRequestApproval: boolean = false

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Getter('identity')
  public identity: AcsUserProfile

  @Watch('selectedTenant')
  public onSelectedTenantChanged(): void {
    this.requireRequestApproval = false
  }

  public get tenantName(): string {
    if (!this.identity) return null
    return this.identity.CompanyName
  }

  public mounted(): void {
    this.debouncedOnCustomerNumberChange = debounce(async () => {
      await this.validateCustomerNumber()
    }, 250)
  }

  private async validateCustomerNumber(): Promise<boolean> {
    if (this.isCustomerNumberValidating) return false
    this.customers = []
    const provider = <any>this.$refs.customerNumberValidator
    const result = await provider.validate()
    if (!result.valid) return false
    this.isCustomerNumberValidating = true
    let isValid = false
    try {
      const customers = await this._customerService.getCustomers(this.customerNumber)
      if (!customers.length) {
        provider.applyResult({
          errors: ['Tenant could not be found.'],
          valid: false,
          failedRules: {},
        })
      } else {
        isValid = true
        this.customers = customers
        this.selectedTenant = customers[0].vendorId
        provider.applyResult({
          errors: [],
          valid: true,
          failedRules: {},
        })
      }
    } catch (e) {
      console.log(e)
      provider.applyResult({
        errors: ['There was an error validating the Customer Number. Please try again later or contact our support.'],
        valid: false,
        failedRules: {},
      })
    }
    this.isCustomerNumberValidating = false
    return isValid
  }

  public async onCreateCustomerProfile(requestApproval: boolean): Promise<void> {
    const form = <any>this.$refs.form
    if (!(await form.validate())) return
    this.isLoading = true
    try {
      const profile = await this._customerProfileService.createCustomerProfile(
        this.profileName,
        (this.useCustomerNumber && this.selectedTenant) || null,
        requestApproval,
      )
      if (requestApproval && this.requireRequestApproval) {
        this.$popToast(
          ToastType.Success,
          'An access request has been sent to the administrator of the selected Tenant.',
        )
      }
      this.$emit('created', profile)
    } catch (e) {
      console.log(e)
      if (e instanceof ApiError && e.type === 'm42bps.common.packages.Exceptions.MissingRequestApprovalException') {
        this.requireRequestApproval = true
      } else {
        this.$popToast(
          ToastType.Error,
          'There was an error creating the Customer Profile. Please try again later or contact our support.',
        )
      }
    }
    this.isLoading = false
  }

  public reset(): void {
    this.profileName = null
    this.isLoading = false
    this.useCustomerNumber = false
    this.customerNumber = null
    this.isCustomerNumberValidating = false
    this.customers = []
    this.selectedTenant = null
    this.requireRequestApproval = false
    const form = <any>this.$refs.form
    if (form) form.reset()
  }
}
