export enum PackageAssetType {
  Unknown,
  Image,
  Document,
  Logo,
}

export interface PackageAsset {
  readonly name: string
  readonly assetType: PackageAssetType
  readonly id: string
  readonly uri: string
}
