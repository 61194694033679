

















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {
  M42AddRemoveList,
  M42Aside,
  M42Button,
  M42Icon,
  M42Input,
  M42Select,
  M42Spinner,
  ToastType,
} from '@matrix42/ignition-vue'
import { CustomerProfile } from '@/models/customer-profile'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { InstallTarget } from '@/models/install-target'

@Component({
  components: {
    M42Aside,
    M42AddRemoveList,
    M42Button,
    M42Icon,
    M42Input,
    M42Spinner,
    M42Select,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class EditCustomerProfileAside extends Vue {
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  public isLoading: boolean = false
  public profileName: string = null
  public deleteTargets: string[] = []

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Prop({
    type: Object,
    default: () => <CustomerProfile>null,
  })
  public target: CustomerProfile

  public get isDirty(): boolean {
    if (!this.target || !this.profileName) return false
    return this.target.name !== this.profileName || !!this.deleteTargets.length
  }

  public get installationEnvironments(): InstallTarget[] {
    if (!this.target) return []
    const targets: InstallTarget[] = []
    for (const target of this.target.installationEnvironments) {
      if (this.deleteTargets.filter((t) => t === target.host).length) continue
      targets.push(target)
    }
    return targets
  }

  public async onSave(): Promise<void> {
    const form = <any>this.$refs.form
    if (!(await form.validate())) return
    this.isLoading = true
    try {
      await this._customerProfileService.updateCustomerProfile(this.target.id, this.profileName)
      for (const target of this.target.installationEnvironments) {
        if (this.deleteTargets.filter((t) => t === target.host).length) {
          await this._customerProfileService.deleteInstallTarget(target)
        } else {
          await this._customerProfileService.saveInstallTarget(target)
        }
      }
      this.$emit('saved', this.target)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error saving the Customer Profile. Please try again later or contact our support.',
      )
      this.isLoading = false
    }
  }

  public onAddTarget(): void {
    window.localStorage.setItem('M42-TARGET-PROFILE-TMP', this.target.id)
    this.$store.commit('require_environment', true)
  }

  public onDeleteTarget(item: InstallTarget): void {
    this.deleteTargets.push(item.host)
  }

  public reset(): void {
    this.profileName = null
    this.deleteTargets = []
    this.isLoading = false
    if (this.target) {
      this.profileName = this.target.name
    }
  }
}
