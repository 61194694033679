






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { M42Banner, M42Button, M42Modal, ToastType } from '@matrix42/ignition-vue'
import { Resolve } from '@dvolper/tsdi'
import { CustomerProfileService } from '@/services/customer-profile-service'
import { CustomerProfile } from '@/models/customer-profile'

@Component({
  components: {
    M42Banner,
    M42Button,
    M42Modal,
  },
})
export default class RecreateCustomerProfileModal extends Vue {
  @Resolve
  private readonly _customerProfileService: CustomerProfileService
  public isLoading: boolean = false

  @Prop({
    type: Boolean,
    default: false,
  })
  public open: boolean

  @Prop({
    type: Object,
    default: () => <CustomerProfile>null,
  })
  public target: CustomerProfile

  public async onRecreateCustomerProfile(): Promise<void> {
    this.isLoading = true
    try {
      await this._customerProfileService.recreateCustomerProfile(this.target.id)
      this.$emit('recreated', this.target)
    } catch (e) {
      console.log(e)
      this.$popToast(
        ToastType.Error,
        'There was an error requesting access for the selected Customer Profile. Please try again later or contact our support.',
      )
      this.isLoading = false
    }
  }

  public reset(): void {
    this.isLoading = false
  }
}
